<!--
 * @Editors: pan long
 -->
<template>
  <div>
    <Header title="juliang"></Header>
    <BannerTitle title="juliang"></BannerTitle>
    <div class="wow slideInUp jlBox">
      <div class="topBox">
        <div class="titBox">{{ $t("juliang.listTop.topTit") }}</div>
        <div class="descBox">{{ $t("juliang.listTop.topDesc") }}</div>
      </div>
      <ul class="wow slideInUp imgBox">
        <li><img src="@/assets/images/v2/jl-1.png" alt="icon"></li>
        <li><img src="@/assets/images/v2/jl-2.png" alt="icon"></li>
        <li><img src="@/assets/images/v2/jl-3.png" alt="icon"></li>
        <li><img src="@/assets/images/v2/jl-4.png" alt="icon"></li>
        <li><img src="@/assets/images/v2/jl-5.png" alt="icon"></li>
        <li><img src="@/assets/images/v2/jl-6.png" alt="icon"></li>
        <li><img src="@/assets/images/v2/jl-7.png" alt="icon"></li>
      </ul>
      <ul class="wow slideInUp souceBox">
        <li>
          <img src="@/assets/images/v2/jl-8.png" alt="">
          <b>{{ $t("juliang.listTop.tips1") }}</b>
          <span>{{ $t("juliang.listTop.desc1") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-9.png" alt="">
          <b>{{ $t("juliang.listTop.tips2") }}</b>
          <span>{{ $t("juliang.listTop.desc2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-10.png" alt="">
          <b>{{ $t("juliang.listTop.tips3") }}</b>
          <span>{{ $t("juliang.listTop.desc3") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-11.png" alt="">
          <b>{{ $t("juliang.listTop.tips4") }}</b>
          <span>{{ $t("juliang.listTop.desc4") }}</span>
        </li>
      </ul>
      <div class="wow slideInUp midBox">
        <ul class="wow slideInUp MidTip">
          <li class="topLi">
            <img class="topPic" src="@/assets/images/v2/jl-12.png" alt="" />
            <div class="wow slideInUp topContent">
              <div class="topContTit">{{ $t("juliang.ListMiddle.tips1") }}</div>
              <p class="topContDes">{{ $t("juliang.ListMiddle.desc1") }}</p>
              <div class="numBox">
                <div class="num1" style="margin-right:54px;">
                  <p>{{ $t("juliang.ListMiddle.num1") }}</p>
                  <div>{{ $t("juliang.ListMiddle.ndes1") }}</div>
                </div>
                <div class="num1">
                  <p>{{ $t("juliang.ListMiddle.num2") }}</p>
                  <div>{{ $t("juliang.ListMiddle.ndes2") }}</div>
                </div>
              </div>
            </div>
          </li>
          <li class="bottomLi">
            <div class="wow slideInUp MidContent">
              <div>
                {{ $t("juliang.ListMiddle.tips2") }}
                <div>{{ $t("juliang.ListMiddle.tips3") }}</div>
              </div>
              <p>{{ $t("juliang.ListMiddle.desc2") }}</p>
            </div>
            <img class="botPic" src="@/assets/images/v2/jl-13.png" alt="" />
          </li>
        </ul>
      </div>

      <div class="wow slideInUp topBox">
        <div class="titBox">{{ $t("juliang.listBottom.botTit") }}</div>
        <div class="descBox">{{ $t("juliang.listBottom.botDes") }}</div>
      </div>
      <ul class="wow slideInUp costBox">
        <li>
          <img src="@/assets/images/v2/jl-14.png" alt="">
          <b>{{ $t("juliang.listBottom.tips1") }}</b>
          <span>{{ $t("juliang.listBottom.desc1") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-15.png" alt="">
          <b>{{ $t("juliang.listBottom.tips2") }}</b>
          <span>{{ $t("juliang.listBottom.desc2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-16.png" alt="">
          <b>{{ $t("juliang.listBottom.tips3") }}</b>
          <span>{{ $t("juliang.listBottom.desc3") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-17.png" alt="">
          <b>{{ $t("juliang.listBottom.tips4") }}</b>
          <span>{{ $t("juliang.listBottom.desc4") }}</span>
        </li>
      </ul>
      <div class="wow slideInUp btoTit">{{ $t("cili.listBottom.title") }}</div>
      <ul style="margin-bottom:160px;" class="wow slideInUp topTip">
        <li>
          <img src="@/assets/images/v2/jl-18.png" alt="" />
          <b>{{ $t("cili.listBottom.tips1") }}</b>
          <span style="width:320px;">{{ $t("cili.listBottom.desc1") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-19.png" alt="" />
          <b>{{ $t("cili.listBottom.tips2") }}</b>
          <span style="width:320px;">{{ $t("cili.listBottom.desc2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/v2/jl-20.png" alt="" />
          <b>{{ $t("cili.listBottom.tips3") }}</b>
          <span style="width:320px;">{{ $t("cili.listBottom.desc3") }}</span>
        </li>
      </ul>

    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
export default {
  name: "linyuan",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline,
  }
};
</script>

<style lang="scss">
.jlBox {
  width: 1300px;
  margin: auto;

   .topContTit {
      // height:56px;
      font-size:40px;
      font-family:PingFangSC-Semibold,PingFang SC;
      font-weight:600;
      color:rgba(51,51,51,1);
      line-height:56px;
    }

    .topContDes {
      width:580px;
      height:120px;
      font-size:16px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:30px;
      margin: 24px 0;
    }

    .numBox{
      display: flex;
      align-items: center;

      .num1 {
        display: flex;
        flex-direction: column;

        p {
          height:36px;
          font-size:30px;
          font-family:DIN-Bold,DIN;
          font-weight:bold;
          color:rgba(51,51,51,1);
          line-height:36px;
        }

        div {
          height:22px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(153,153,153,1);
          line-height:24px;
        }
      }
    }

    .topLi {
    margin-bottom: 134px;
  }

  .titBox {
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Semibold,PingFang SC;
    font-weight: 600;
    color: rgba(51,51,51,1);
    line-height: 56px;
    text-align: center;
  }

  .descBox {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: rgba(153,153,153,1);
    line-height: 25px;
    text-align: center;
    margin-top:8px;
  }

  .imgBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 69px 62px 119px 62px;

    li,img {
      width:110px;
      height:110px;
    }
  }

  .midBox {
    width: 800px;
    margin: auto;

    .MidTip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      margin-top:115px;
      margin-bottom: 166px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .topPic {
          width:493px;
          height:456px;
          margin-right: 70px;
        }

        
        .botPic {
          width:504px;
          height:378px;
          margin-left: 62px;
        }

        .MidContent {
          width: 500px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-items: center;
          justify-content: space-between;

          div{
            width:490px;
            // height:112px;
            font-size:40px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:56px;
          }

          p{
            width:490px;
            height:56px;
            font-size:16px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(153,153,153,1);
            line-height:30px;
            margin-top: 18px;
          }
        }
      }

      .middleLi {
        margin-top: 93px;
        margin-bottom: 167px;
      }
    }
  }

  .souceBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: space-between;

      img {
        width:237px;
        height:492px;
      }

      b {
        width:202px;
        height:28px;
        font-size:20px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        line-height:28px;
        text-align: center;
        margin-top: 55px;
        margin-bottom: 12px;
      }

      span {
        width:236px;
        height:87px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:26px;
        text-align: center;
      }
    }
  }

  .costBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:83px;
    margin-bottom: 160px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: space-between;

      img {
        width:149px;
        height:132px;
      }

      b {
        height:28px;
        font-size:20px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        line-height:28px;
        margin: 54px 0 16px 0;
      }

      span {
        width:236px;
        height:60px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:30px;
        text-align: center;
      }
    }
  }
  .topTip {
    display: flex;
    justify-content: space-between;

    li{
      // flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 72px;
        height: 72px;
      }

      b {
        font-size:20px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        margin-top: 30px;
      }

      span {
        width:202px;
        height:78px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:26px;
        text-align: center;
        margin-top: 13px;
      }
    }
  }  
  .btoTit {
    height:56px;
    text-align: center;
    line-height: 56px;
    font-size:40px;
    font-family:PingFangSC-Semibold,PingFang SC;
    font-weight:600;
    color:rgba(51,51,51,1);
    margin-bottom: 90px;
  }
}
</style>
